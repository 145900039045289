import "twin.macro"
import React from "react"
import tw from "twin.macro"

const CalendarItem = ({
    color,
    duration,
    empty = false,
    organizer,
    scheduleend,
    schedulestart,
    start,
    title,
    venue,
}) => {

  return (
    <td
      css={[
        tw`h-[144px] relative z-10`,
      ]}
    >
      {!empty && (
        <div
          css={[
            tw`absolute w-full`,

            start == 0 && duration == 30 && tw`h-[72px] top-[0px]`,
            start == 0 && duration == 45 && tw`h-[108px] top-[0px]`,
            start == 0 && duration == 60 && tw`h-[144px] top-[0px]`,
            start == 0 && duration == 90 && tw`h-[226px] top-[0px]`,

            start == 5 && duration == 30 && tw`h-[72px] top-[12px]`,
            start == 5 && duration == 45 && tw`h-[108px] top-[12px]`,
            start == 5 && duration == 60 && tw`h-[144px] top-[12px]`,
            start == 5 && duration == 90 && tw`h-[226px] top-[12px]`,

            start == 15 && duration == 30 && tw`h-[72px] top-[38px]`,
            start == 15 && duration == 45 && tw`h-[108px] top-[38px]`,
            start == 15 && duration == 60 && tw`h-[144px] top-[38px]`,
            start == 15 && duration == 90 && tw`h-[226px] top-[38px]`,

            start == 30 && duration == 30 && tw`h-[72px] top-[70px]`,
            start == 30 && duration == 45 && tw`h-[108px] top-[70px]`,
            start == 30 && duration == 60 && tw`h-[144px] top-[70px]`,
            start == 30 && duration == 90 && tw`h-[226px] top-[70px]`,

            start == 45 && duration == 30 && tw`h-[72px] top-[108px]`,
            start == 45 && duration == 45 && tw`h-[108px] top-[108px]`,
            start == 45 && duration == 60 && tw`h-[144px] top-[108px]`,
            start == 45 && duration == 90 && tw`h-[226px] top-[108px]`,

            color?.name == 'Panel' && tw`bg-[#FF8BAF]`,
            color?.name == 'Paneldiskussion' && tw`bg-[#FF8BAF]`,
            color?.name == 'Workshop' && tw`bg-[#A6BDCC]`,
            color?.name == 'Lightning Talk' && tw`bg-[#FFF379]`,
            color?.name == 'Präsentation mit Diskussion' && tw`bg-[#C7B5FF]`,

            color?.name == undefined && tw`bg-[#C7B5FF]`,
          ]}
        >
          <div tw="p-2 text-left top-0 left-0">
            <div tw="text-sm font-bold">{title}</div>
            <div tw="">{schedulestart}–{scheduleend} Uhr</div>
          </div>
          <div tw="absolute p-2 text-left bottom-0 left-0">
            <div tw="text-sm font-bold">{organizer}</div>
          </div>
        </div>
      )}
    </td>
  )
}

export default CalendarItem
